import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles(
  ({palette, breakpoints, spacing}) => ({
    container: (maxWidth) => ({
      paddingTop: spacing(22),
      paddingBottom: spacing(16.5) /*`${spacing(
        22,
        maxWidth ? 0 : 2,
        16.5,
        maxWidth ? 0 : 2,
      )} !important`, */,
      [breakpoints.down(900)]: {
        paddingTop: spacing(14),
        paddingBottom: spacing(1.875),
        // padding: `${spacing(14, 1.875, 1.875, 1.875)} !important`,
      },
    }),
    justSearchContainer: (maxWidth) => ({
      padding: `${spacing(
        12,
        maxWidth ? 0 : 2,
        0,
        maxWidth ? 0 : 2,
      )} !important`,
    }),
    root: {
      background: `url(/images/seo-bg.jpg);`,
      backgroundSize: 'cover',
      // maxHeight: spacing(80),
      [breakpoints.up(800)]: {
        height: spacing(80),
      },
    },
    breadcrumb: {
      color: palette.common.white,
    },
    desktopSearch: {
      marginTop: 100,
      [breakpoints.down(900)]: {
        display: 'none',
      },
    },
    mobileSearch: {
      position: 'relative',
      top: 50,
      [breakpoints.up(900)]: {
        display: 'none',
      },
    },
  }),
  {index: 2},
)
